// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation DossierWorkflowBulkTransitionDossiers(
    $selectedIds: [ID!],
    $navigationSlug: String!,
    $dossierTypeSlug: String!,
    $workflowId: ID!,
    $transitionName: String!
  ) {
    result: dossierWorkflowBulkTransitionDossiers(
      ids: $selectedIds,
      navigationSlug: $navigationSlug,
      dossierTypeSlug: $dossierTypeSlug,
      workflowId: $workflowId,
      transitionName: $transitionName
    ) {
      successful
      metadata
    }
  }
`;

export default MUTATION;
